import { FormControl, IFormControlProps, useTheme } from 'native-base';
import { RefAttributes } from 'react';
import RSelect, { SelectInstance, StylesConfig } from 'react-select';
import Select from 'react-select/dist/declarations/src/Select';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';
import { COLORS, FONTS, THEME_INPUT_HEIGHT } from 'src/providers/design-provider/constants';

export type StateSelectManagerProps = StateManagerProps<SelectItem> & RefAttributes<Select>;

export interface SelectItem {
  label: string;
  value: string;
}

export interface SelectProps {
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  helperText?: string;
  items: SelectItem[];
  formControlStyle?: IFormControlProps;
  innerRef?: React.RefObject<SelectInstance>;
}

export const SearchableSelect = ({
  label,
  items,
  helperText,
  isRequired,
  formControlStyle,
  innerRef,
  ...props
}: SelectProps & StateSelectManagerProps) => {
  const { colors } = useTheme();

  const colourStyles: StylesConfig<SelectItem> = {
    control: styles => ({
      ...styles,
      backgroundColor: COLORS.grey,
      height: THEME_INPUT_HEIGHT * 4,
      borderWidth: 1,
      borderColor: colors.muted[300],
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled ? undefined : isSelected ? colors.primary[300] : isFocused ? colors.lightBlue[100] : undefined,
        color: isDisabled ? '#ccc' : isSelected ? colors.white : 'black',
        cursor: isDisabled ? 'not-allowed' : 'default',
        fontSize: 12,
        fontFamily: FONTS.REGULAR,
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled ? (isSelected ? colors.primary[300] : colors.lightBlue[100]) : undefined,
        },
      };
    },
    noOptionsMessage: styles => ({ ...styles, fontSize: 12, fontFamily: FONTS.REGULAR }),
    input: styles => ({ ...styles, fontSize: 12, fontFamily: FONTS.REGULAR }),
    placeholder: styles => ({ ...styles, fontSize: 12, fontFamily: FONTS.REGULAR }),
    singleValue: styles => ({ ...styles, fontSize: 12, fontFamily: FONTS.REGULAR }),
    container: styles => ({ ...styles, zIndex: 2000 }),
    menu: styles => ({ ...styles, zIndex: 10000 }),
    menuList: styles => ({ ...styles, zIndex: 10000 }),
  };

  return (
    <FormControl {...formControlStyle}>
      {label && <FormControl.Label>{label}</FormControl.Label>}
      {/* Adding the ref/component as anyto remove the type mismatch error */}
      <RSelect {...props} ref={innerRef as any} options={items} styles={colourStyles} />
      {helperText && <FormControl.HelperText>{helperText}</FormControl.HelperText>}
    </FormControl>
  );
};
