import { gql } from '@apollo/client';

export const TRAVEL_SCHEDULE_FRAGMENT = gql`
  fragment TravelScheduleFragment on TravelSchedule {
    id
    branchId
    routeId
    serviceCharge
    travelFare
    totalFare
    tripId
    trip {
      id
      time
      date
      seatsAvailable
      isCheckinAvailable
      vehicleClass {
        id
        name
      }
    }
    route {
      destination {
        code
        name
        state
      }
      branch {
        name
        address {
          state
          country
        }
      }
    }
  }
`;
