import { useLinkProps } from '@react-navigation/native';
import moment from 'moment';
import { Box, Button, Stack } from 'native-base';
import { useEffect, useRef, useState } from 'react';
import { DateTimePicker } from 'src/components/date-picker/DatePicker';
import { SelectItem } from 'src/components/select/Select';
import { BodyText } from 'src/components/typography/BodyText';
import { ItenaryInput } from 'src/graphql/generated/types';
import { FindTerminalQuery_findTerminal_route, useFindTerminalLazyQuery } from 'src/graphql/ticketing_api/queries/find-terminal';
import { useGetAvailablityLazyQuery } from 'src/graphql/ticketing_api/queries/get-availability';
import { GetTerminalsQuery_getTerminals_Terminal, useGetTerminalsQuery } from 'src/graphql/ticketing_api/queries/get-terminal';
import { AVAILABLITY_SCREEN_NAME, RESERVATION_STACK_NAME } from 'src/navigation/constants';
import { useReservation } from 'src/providers/reservation-provider/ReservationProvider';
import { PassengerCounter, PassengerSet } from 'src/screens/book-seat/components/PassengerCounter';
import { capitalize } from 'src/utils/string';
import { SearchableSelect } from 'src/components/select/SearchableSelect';
import { MultiValue, SelectInstance, SingleValue } from 'react-select';

export const SearchBox = () => {
  const { setAvailableSchedules, setSearch, clearCache } = useReservation();
  const [selectedTerminal, setSelectedTerminal] = useState<GetTerminalsQuery_getTerminals_Terminal>();
  const [selectedRoute, setSelectedRoute] = useState<FindTerminalQuery_findTerminal_route>();
  const [departureDate, setDepartureDate] = useState<Date>();
  const [adult, setAdult] = useState<number>(1);
  const [child, setChild] = useState<number>(0);
  const [infant, setInfant] = useState<number>(0);
  const { data: terminalsList, loading: loadingBranches } = useGetTerminalsQuery();
  const [findTerminal, { data: terminal, loading: loadingTerminals }] = useFindTerminalLazyQuery();
  const selectRef = useRef<SelectInstance>(null);

  const { onPress: goToAvailablity } = useLinkProps({
    to: {
      screen: RESERVATION_STACK_NAME,
      params: {
        screen: AVAILABLITY_SCREEN_NAME,
        params: {
          adult: String(adult),
          child: String(child),
          infant: String(infant),
          routeId: selectedRoute?.id,
          terminalId: selectedTerminal?.id,
          departureDate: moment(departureDate).format('YYYY-MM-DD'),
        },
      },
    },
  });
  const [getAvailableSchedules, { loading: loadingResults }] = useGetAvailablityLazyQuery();

  const terminalOptions =
    terminalsList?.getTerminals.map(t => ({
      label: `${capitalize(t.address.state)} -> ${capitalize(t.name.toUpperCase())}`,
      value: t.id,
    })) ?? [];
  const destinationList =
    terminal?.findTerminal.routes.map(r => ({
      label: `${capitalize(r.destination.state ?? '')} -> ${capitalize(r.destination.name)}`,
      value: r.id,
    })) ?? [];

  useEffect(() => {
    if (selectedTerminal) {
      findTerminal({ variables: { findTerminalId: selectedTerminal.id } });
    }
  }, [selectedTerminal]);

  const onSearch = async () => {
    const depature: ItenaryInput = {
      adult: String(adult),
      child: String(child),
      infant: String(infant),
      routeId: selectedRoute?.id!,
      terminalId: selectedTerminal?.id!,
      date: moment(departureDate).format('YYYY-MM-DD'),
    };

    const response = await getAvailableSchedules({
      variables: {
        input: { depature },
      },
    });

    // clear reservation cache
    await clearCache();

    if (response.data) {
      setSearch({
        adult,
        child,
        infant,
        terminal: selectedTerminal?.name ?? '',
        terminalState: selectedTerminal?.address.state ?? '',
        destination: selectedRoute?.destination.name ?? '',
        destinationState: selectedRoute?.destination.state ?? '',
        date: moment(departureDate).format('YYYY-MM-DD'),
      });
      setAvailableSchedules(response.data.getAvailableSchedule);
      goToAvailablity();
    }
  };

  const onTerminalChange = (terminalId: string) => {
    const newTerminal = terminalsList?.getTerminals.find(t => t.id === terminalId);
    setSelectedRoute(undefined);
    setSelectedTerminal(newTerminal);
  };

  const onDestinationChange = (destinationId: string) => {
    const newRoute = terminal?.findTerminal.routes.find(r => r.id === destinationId);
    setSelectedRoute(newRoute);
  };

  const onPassengerCountChange = (set: PassengerSet) => {
    setAdult(set.adult);
    setChild(set.child);
    setInfant(set.infant);
  };

  const hasPassenger = adult + child + infant > 0;
  const canSubmit = hasPassenger && departureDate && selectedTerminal && selectedRoute;

  return (
    <Box shadow={2} py={5} px={10} width="100%" bgColor="white" borderBottomRadius="md" alignSelf="center">
      <Stack space={2} direction={{ base: 'column', md: 'row' }} justifyContent="space-around">
        <SearchableSelect
          label="From"
          formControlStyle={{ width: { base: 'full', md: '19%' }, isRequired: true, zIndex: 2 }}
          placeholder="Select departure"
          isRequired
          items={terminalOptions}
          isLoading={loadingBranches}
          isMulti={false}
          onChange={(newValue: SingleValue<SelectItem> | MultiValue<SelectItem>) => {
            selectRef.current?.clearValue();
            onTerminalChange((newValue as SingleValue<SelectItem>)?.value ?? '');
          }}
        />

        <SearchableSelect
          innerRef={selectRef}
          label="To"
          formControlStyle={{ width: { base: 'full', md: '19%' }, isRequired: true, zIndex: 1 }}
          placeholder="Select destination"
          items={destinationList}
          isLoading={loadingTerminals}
          onChange={(newValue: SingleValue<SelectItem> | MultiValue<SelectItem>) => {
            onDestinationChange((newValue as SingleValue<SelectItem>)?.value ?? '');
          }}
        />

        <DateTimePicker
          width={{ base: 'full', md: '19%' }}
          label="Departing On"
          mode="date"
          isRequired
          minimumDate={new Date()}
          onDateChange={d => setDepartureDate(d)}
        />
        <PassengerCounter
          width={{ base: 'full', md: '18%' }}
          label="Passengers"
          isRequired
          onComplete={onPassengerCountChange}
          defaults={{ adult, child, infant }}
        />
        <Button
          size="lg"
          alignSelf="center"
          width={{ base: 'full', md: '18%' }}
          mt={{ base: 2, md: 7 }}
          onPress={onSearch}
          disabled={!canSubmit}
          isDisabled={!canSubmit}
          isLoading={loadingResults}
        >
          <BodyText text="Search" color="#fff" />
        </Button>
      </Stack>
    </Box>
  );
};
